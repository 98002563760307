<template>
  <b-container>
    <calculator-detail title="Dana Investasi" description="">
      <div slot="calculator" class="mt-4">
        <b-form-group label-cols-sm="4" label="Periode investasi">
          <b-input-group>
            <b-form-select
              v-model="investmentPeriodType"
              :options="options"
              @change="calculate()"
            >
              <template #first>
                <b-form-select-option value="" disabled
                  >-- Pilih Periode --</b-form-select-option
                >
              </template></b-form-select
            >
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Investasi Awal">
          <b-input-group prepend="Rp" type="number">
            <b-form-input
              v-model="initialInvestment"
              v-currency
              @keyup="calculate()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          :label="`Investasi rutin per ${periodLabel}`"
        >
          <b-input-group prepend="Rp" type="number">
            <b-form-input
              v-model="regularInvestment"
              v-currency
              @keyup="calculate()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Jangka waktu">
          <b-input-group append="Tahun" type="number">
            <b-form-input
              v-model="investmentPeriod"
              @keyup="calculate()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-cols-sm="4"
          label="Ekspektasi tingkat bunga investasi per tahun"
        >
          <b-input-group append="%" type="number">
            <b-form-input
              v-model="annualInvestmentInterestRateExpectation"
              @keyup="calculate()"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-sm="4" label="Hasil investasi per tahun">
          <b-form-input v-model="investmentReturn" disabled></b-form-input>
        </b-form-group>
      </div>
    </calculator-detail>
  </b-container>
</template>

<script>
import CalculatorDetail from '../../components/CalculatorDetail.vue'
import * as formulajs from '@formulajs/formulajs'

export default {
  name: 'DanaInvestasi',
  components: {
    CalculatorDetail,
  },
  data() {
    return {
      initialInvestment: '',
      regularInvestment: '',
      investmentPeriod: '',
      annualInvestmentInterestRateExpectation: '',
      investmentPeriodType: '',
      investmentReturn: '',
      periodLabel: 'bulan',
      options: [
        { value: 'Bulan', text: 'Bulanan' },
        { value: 'Tahun', text: 'Tahunan' },
      ],
      mode: 0,
    }
  },
  computed: {
    r() {
      return parseFloat(this.annualInvestmentInterestRateExpectation) / 100
    },
    n() {
      return parseFloat(this.investmentPeriod)
    },
    pv() {
      return this.toZeroMinus(
        this.unformatCurrency(this.initialInvestment) * -1
      )
    },
    pmt() {
      return this.toZeroMinus(
        this.unformatCurrency(this.regularInvestment) * -1
      )
    },
  },
  methods: {
    calculate() {
      const label = this.investmentPeriodType
      let result = 0

      this.periodLabel = label.toLowerCase()

      if (label === 'Tahun') {
        result = this.annualCalculation()
      } else {
        result = this.monthlyCalculation()
      }

      this.investmentReturn = this.formatToCurrency(result)
    },
    monthlyCalculation() {
      const rPerMonth = this.r / 12
      const nPerMonth = this.n * 12

      const fv = formulajs.FV(
        rPerMonth,
        nPerMonth,
        this.pmt,
        this.pv,
        this.mode
      )
      return fv.toFixed(2)
    },
    annualCalculation() {
      const fv = formulajs.FV(this.r, this.n, 0, this.pv, this.mode)
      return fv.toFixed(2)
    },
  },
}
</script>
